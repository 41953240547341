<template>
    <v-card>
        <s-toolbar label="Lista de Pallet" dark color="#8e8f91"></s-toolbar>
        <v-container>
            <v-row   >
                <!-- <v-col cols="12"> -->
                    <!-- <v-row> -->
                        <v-col cols="4">
                            <s-select-definition
                                :def="1181"
                                label="Empaque"
                                v-model="TypeDestiny"
                                return-object
                            >
                            </s-select-definition>
                        </v-col>
                        <v-col cols="4">
                            <s-text
                                label="ID Lote"
                                number
                                
                                v-model="filter.RcfID">                                   
                            </s-text>
                        </v-col>
                    <!-- </v-row> -->
                <!-- </v-col> -->
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :items="itemsLots"
                        item-key="PltID"
                        dense
                        :headers="headerLots"
                        :items-per-page="15"									 								
                        disable-sort
        
                        
                    > 		
                        <template v-slot:item.PltID="row">
                            <v-chip
                                small
                                dark											 
                                color="success"
                            >
                                {{ row.item.PltID + "-" + row.item.PltCorrelative }}
                            </v-chip>
                        </template>
                        <template v-slot:item.RcfID="row">
                            
                                {{ row.item.RcfID + "-" + row.item.RcfCode }}
                            
                        </template>    
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    import _sPackingService from "../../../services/FreshProduction/PackingService";
	 
    export default {

        // components: { qrCode },
        
        data: () => ({
			filter: {},
            TypeDestiny: {},
            itemsLots: [],
            headerLots: [
				/*{ text: "Tranferir", value: "Tranfer" },*/

				{ text: "ID Pallet", value: "PltID" }, 
				{ text: "LOTE Prod.", value: "RcfID" }, 
				{ text: "Cantidad de Cajas", value: "PldQuantityBoxes" },
				{ text: "Variedad", value: "VrtName" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				{ text: "ID Tarima", value: "LlpID" },
				{ text: "Pre Packing", value: "PckPrePackingNumber" },
				{ text: "Pedido", value: "OrdID" },
				 
			],
        }),
        watch: {
            "filter.RcfID"(){
                this.listLotsUsed();
            },
            "TypeDestiny"(){
                this.listLotsUsed();
            }
        },
        methods: {
             listLotsUsed(){
                console.log("filter", this.filter);
                console.log("filter", this.TypeDestiny);
                
                this.filter.TypeDestinyPacking = this.TypeDestiny.DedAbbreviation;

                if (this.filter.RcfID == "" || this.filter.TypeDestinyPacking == null) {
                    return;
                }
                 

                _sPackingService.searchLotsUsed( this.filter , this.$fun.getUserID())
                    .then(r => {
                        if (r.status = 200) {
                            this.itemsLots = r.data;
                            console.log("this.itemsLots", this.itemsLots);
                        }
                    });
             }
        },
        mounted(){
           
        }

    }

</script>